const main = async () => {
    // async load axios module
    const { default: axios } = await import(/* webpackChunkName: "axios" */ 'axios');

    const fetchVimeoAlbum = async (limit) => {
        try {
            const response = await axios.get('/actions/vimeo-api-module/proxy/get-vimeo-album', {
                params: {}
            });
            // return response from vimeo api module
            return response.data.data.slice(0,limit);
        } catch (errors) {
            console.error(errors);
        }
    }

    const createVideoElement = item => {
        let title = item.name;
        let videoDate = new Date(item.created_time);
        let convertedDate = videoDate.toLocaleString('default', { month: 'long', day: '2-digit', year: 'numeric' });
        let description = item.description;
        let image = item.pictures.base_link;
        let url = item.link;

        const videoWrapper = document.createElement('article');
        videoWrapper.classList.add('card', 'card-horiz', 'mb-3');
        videoWrapper.setAttribute('aria-label', `Video: ${title}`)

        videoWrapper.innerHTML = `
          <div class="horiz-card-img">
            <div class="object-fit-wrapper">
              <img class="object-fit-img" src='${image}'>
            </div>
          </div>
          <div class='card-body'>
            <div class='small mb-1'>${convertedDate}</div>
            <h3 class='h5 mb-0'>
              <a class="absolute-link" href='${url}'>${title}</a>
            </h3>
          </div>
        `;
        // return video element
        return videoWrapper;
    }

    const createVideoList = videoItems => {
        const videoList = document.querySelector('.dialog .placeholder');

        if (Array.isArray(videoItems) && videoItems.length > 0) {
            videoItems.map(videoItem => {
                videoList.appendChild(createVideoElement(videoItem));
            });
        } else if (videoItems) {
            videoList.appendChild(createVideoElement(videoItems));
        }
    }
    // return array of videos
    return createVideoList(await fetchVimeoAlbum(1));
};

main().then(() => {
    let dialogPlaceholderVideos = document.querySelectorAll('.placeholder-horiz-card');
    dialogPlaceholderVideos.forEach(el => {
        el.remove();
    });
});